import React from "react";
import horizonLogo from "../images/horizon-logo.png"
import vitaliteLogo from "../images/logo-vitalite2020.png"
import medaviLogo from "../images/medavie-logo.png"
import nursingLogo from "../images/NBANH_horizontal.png"
import {Link} from "gatsby"

export default function FooterFR() {
    return (<>
            <div className={"container-xl"}>
                <div className={"r jc ac mt0 fww"}>
                    <img className={"mx4 p3"} width="220" src={horizonLogo} alt="Horizon Logo"/>
                    <img className={"mx4 p3"} width="220" src={vitaliteLogo} alt="Vitalité Logo"/>
                    <img className={"mx4 p3"} width="220" src={medaviLogo} alt="Medavie Logo"/>
                    <img className={"mx4 p3"} width="220" src={nursingLogo} alt="NBANH Logo"/>
                </div>
            </div>
            <div className={"container-xxl footer mb6"}>
                <hr/>
                <div className={"r-c-lg jsb ac"}>
                    <div className={"r"}>
                        <p className={"mb0 tac-sm"}/>
                    </div>
                    <div className={"r-c-sm mt4-lg"}>
                        <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                              to="https://www2.gnb.ca/content/gnb/fr.html?_ga=2.145083508.1322334766.1613581688-1203811668.1613581688">
                            Commentaires
                        </Link>
                        <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                              to="https://www2.gnb.ca/content/gnb/fr/corporate/communiquez.html">
                            Médias sociaux
                        </Link>
                        <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                              to="https://www2.gnb.ca/content/gnb/fr/admin/declaration_de_confidentialite.html">
                            Confidentialité
                        </Link>
                        <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                              to="https://www2.gnb.ca/content/gnb/fr/admin/decharge.html">
                            Décharge
                        </Link>
                    </div>
                </div>
                {/*<div className={"r jc-lg"}>*/}
                {/*    <p className={"py3 "}><Link className={"c-green"} target={"_blank"} to={"https://vidcruiter.com/fr/"}>Propulsé*/}
                {/*        par VidCruiter</Link></p>*/}
                {/*</div>*/}
            </div>
        </>
    );
}